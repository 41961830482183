table.thongke th {
    text-align: center !important;
    background-color: var(--primary);
    padding: 0.2rem !important;
    border: 1px solid #000 !important;
    color: #fff;
}

table.thongke td {
    padding: 0.2rem !important;
    text-align: center !important;
    border: 1px solid #000 !important;
}

table.thongke td.left {
    text-align: left !important;
}

table.thongke tr.klh {
    background-color: var(--primary);
}

table.thongke tr.klh td {
    color: #fff;
}

